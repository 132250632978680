@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
//@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
//@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-callout;
@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;
// Menu-based containers
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
 @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

h3 {
  margin-bottom: 0;
}

.button-group {
  margin-bottom: 0;
}

body {
  background: url("../img/background_revamp.jpg") center center;
  height: 100vh;
  overflow-y: scroll;
  background-attachment: fixed;
  //padding-top:3.5rem;

}

h1, h3 {
  font-family: "Roboto Slab", sans-serif;
  color: white;
}

h3 .button, header .button {
  color:rgba(0, 74, 153, 1);
  h3 a {
    font-weight: bolder;
  }
}

p {
  font-family: "Domine", serif;
  color: white;
  text-shadow: 0 0 4px black;
}

.card {
  background: rgba(0, 0, 0, 0.6);
  border: 0;
}

header {
  background: white;
  z-index:1;
}

header, #poetry-list {
  position: fixed;
  top:0;
  left:0;
  width:100%;
}

@media screen and (max-width: 39.9375em) {
  #poetry-list {
    width:100%;
    left:0;
  }
}

/* Medium and up */
@media screen and (min-width: 40em) {
  #poetry-list {
    width: 33vw;
    left: unset;
    top:3rem;
    right:1rem;

  }
}


#poetry-list {
  z-index:2;
  top:2.6rem;
  height:50vh;
  overflow-y:scroll;
  box-shadow:0 5px 5px black;
  .button {
    background: rgba(0, 74, 153, 0.96);
    border-bottom:1px solid #001d43;
  }
}
section {
  top:3rem;


}
#pageContent {
  position:relative;
}


section,aside {
  z-index: 0;
}

.card-section {
  //padding: 1rem 0 0 0;
}

.card-section .small-12.cell h1{
  height:5rem;
  line-height:5rem;
}